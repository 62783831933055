import { Link } from 'gatsby';
import React from 'react';
import Layout from '../../landing/components/layout';
import SEO from '../../landing/components/seo';

const WhatIsMemoristaPage = () => (
  <Layout>
    <SEO title="Memorista: The ultimate guestbook widget for website builders" />

    <div className="container">
      <div className="article">
        <h1>Memorista: The ultimate guestbook widget for website builders</h1>

        <h2>What is Memorista and what are its features</h2>
        <p>
          Memorista is a widget to seamlessly integrate a personal guestbook into your site. Like that your guests can
          easily share their memories and experiences with you and other visitors. And the best part is that Memorista
          takes almost no coding experience and is integrated into any website within minutes.
        </p>
        <p>
          Memorista is the perfect guestbook solution for hotels, restaurants, guest houses, weddings, funerals and
          more. Some of the features of the service include:
        </p>
        <ul>
          <li>Easy guestbook integration into any website</li>
          <li>Customizable guestbook design (title, description and language)</li>
          <li>Plugin for WordPress</li>
          <li>Moderation tools to manage guest submissions </li>
          <li>And more!</li>
        </ul>
        <p>
          What sets Memorista apart from other guestbook options is its simplicity and ease of use. No need for a
          separate guestbook at your physical location, just integrate it into your website for convenience.
        </p>
        <p>
          So give your guests the opportunity to leave their mark with Memorista. <Link to="/">Try Memorista now!</Link>
        </p>

        <h2>How to add a guestbook to your website</h2>
        <p>Adding a guestbook to your website is a simple process. Just follow these steps:</p>
        <ol>
          <li>
            Go to the Memorista website and{' '}
            <a href="/admin" target="_blank">
              create a new account
            </a>
            .
          </li>
          <li>
            Select the widget that corresponds to your website's platform (
            <a href="https://wordpress.org/plugins/memorista/" target="_blank">
              Memorista WordPress plugin
            </a>
            , or copy the code snippet from the settings page in your Memorista admin interface).
          </li>
          <li>Paste the snippet or the WordPress shortcode into the appropriate spot on your website. </li>
          <li>Customize the guestbook however you'd like in your admin interface.</li>
          <li>That's it! Guests can now share their memories on your guestbook page.</li>
        </ol>

        <h2>Why use Memorista instead of other guestbook options</h2>
        <p>
          Not only is Memorista easy to integrate into your website, it also offers a modern and sleek design that fits
          seamlessly with any website aesthetic. Memorista also has a spam filter and guestbook moderation
          functionality. This means that you will not have to build these features by yourself, which can save you time
          and money.
        </p>

        <h2>How to get the most out of Memorista</h2>
        <p>
          In most of the cases just adding the guestbook it will be enough to provide more value to your visitors. In
          some cases however, you might want to hint your guests on what they could write about in your guestbook's
          description. Like that you can easily collect your guests memories and testimonials for future marketing
          purposes. This is especially useful for commercial websites of restaurants, hotels, guest houses etc.
        </p>

        <h2>Examples of guestbooks created with Memorista</h2>
        <p>The following websites already are happy users of Memorista:</p>
        <ul>
          <li>
            An{' '}
            <a href="https://www.pustakalewi.com/" target="_blank">
              Indonesian News Site
            </a>
          </li>
          <li>
            A{' '}
            <a href="https://dalsland-ferien.de/" target="_blank">
              Guesthouse in Sweden
            </a>
          </li>
          <li>And many more!</li>
        </ul>

        <h2>FAQs about Memorista</h2>
        <h3>Is there a WordPress plugin for Memorista?</h3>
        <p>Yes, there is: Memorista WordPress Plugin</p>

        <h3>The registration page states that Memorista is still in its early development. What does this mean?</h3>
        <p>
          This means that we are constantly working to improve and update Memorista. We welcome any feedback or
          suggestions from our users to continue developing the best guestbook widget possible. So if there's something
          you really want, tell us and we'll try to add it!
        </p>

        <h3>Can I customize the design of my guestbook?</h3>
        <p>
          It is currently possible to change the guestbooks title, description and language. More customization options
          like changing the coloring are not yet here but will be coming in the future.
        </p>

        <h3>Is there any spam protection built into Memorista?</h3>
        <p>
          Yes, we have anti-spam measures in place to ensure your guestbook remains free of unwanted entries. In case
          something slips through the spam filter you can still delete entries in your administration interface.
        </p>

        <h3>How do I delete spam entries?</h3>
        <p>In the administration interface, you can simply click on the entry and then choose to delete it.</p>

        <h3>How will the resulting guestbook look like?</h3>
        <p>You can see and test a live guestbook on our demo page.</p>
      </div>
    </div>

    <div className={'call-to-action'}>
      <div className={'container'}>
        <div className={'call-to-action__content'}>
          <h2>Sign up for free</h2>
          <p>Start now and create your own personal Guestbook with Memorista in just 5 minutes.</p>
        </div>

        <div className={'button'}>
          <a href="/admin" target="_blank">
            Create Guestbook
          </a>
        </div>
      </div>
    </div>
  </Layout>
);

export default WhatIsMemoristaPage;
